

body, html, .App {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'regular', '"neo sans arabic"', sans-serif;
    direction: var(--global_enable_rtl);
}

input, label, select, option, textarea {
    font-family: 'regular', '"neo sans arabic"', sans-serif;
}

.App{
    position: relative;
}


.App *{
    scrollbar-width: none;
}

.app_holder .cover {
    display: none;
}

.flex_1 {
    flex: 1;
    overflow: hidden;
}
.flex_2 {
    flex: 2;
    overflow: hidden;

}

.flex_3 {
    flex: 3;
    overflow: hidden;
}

.flex {
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
}

.pointer {
    cursor: pointer;
}

.bold {
    font-weight: bold;
    font-family:  'black', '"neo sans arabic"', sans-serif;;
}

.line-through {
    text-decoration: line-through;
}

.center_crop{
    object-fit: cover;
    object-position: center;
}


.button {
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
}


.button_text {
    width: -webkit-fill-available;
    text-align: center;
}


.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;
}

.toolbar_center {
     flex: 2;
}

.toolbar_start, .toolbar_end {
    flex: 1;
}

.toolbar_center {
    display: flex;
    justify-content: center;
    text-align: center;
}

.toolbar_start, .toolbar_end {
    display: flex;
    justify-content: flex-start;
}

.toolbar_end {
    justify-content: flex-end;
}

.p10 {
    padding: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--global_colorAccent);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--global_colorAccent);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* custom tabs */
.rts___tabs___container {
    height: 100%;
    width: 100%;
    padding: 0px;
}

.rts___tabs {
    padding: 0px;
    background-color:var(--product_catalog_layout_tabs_background);
}
.rts___btn {
    border-radius: 0px;
    border: none;
    font-family: 'black';
    margin: 0px;
    padding: 10px 20px;
    background-color:var(--product_catalog_layout_tabs_background);
    color: var(--product_catalog_layout_tabs_item_textColor);
    font-size: var(--product_catalog_layout_tabs_item_textSize);
    box-shadow: none;
}
.rts___tab___selected {
    background-color:var(--product_catalog_layout_tabs_background);
    border-bottom: 2px solid var(--product_catalog_layout_tabs_indicator);
    color: var(--product_catalog_layout_tabs_item_selected_textColor);
}


.slick-slide div div {
    display: block !important;
}


/* start splash */


.no_content {
    text-align: center;
    min-height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}


.splash_layout {
    position: relative;
    width: 100%;
    height:100vh;
}


.LoginScreen, .ProductCatalog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}


img.splash_block_background {
    max-width: 100%;
    height: 100%;
    object-fit: cover !important;
}
img.splash_block_logo {
    object-fit: contain !important;
}


.opacity_01 {
    opacity: 0.3
}




/* start cart */

.cart_layout {
    height:100vh;
    overflow: scroll;
}

.cart_layout_toolbar {
    display: flex;
    height: 55px;
    overflow: hidden;
}

.cart_layout_content_position_content{
    overflow: hidden;
}
.cart_layout_footer_holder {
    position: absolute !important;
    bottom: var(--cart_layout_footer_marginVertical);
    width: 100%;
    z-index: 10000;
}
.cart_layout_footer {
    width: auto;
    border-style: solid;
}
.cart_layout_footer_positions {
    display: flex;
}
.cart_layout_footer_position_footer_start {
    text-align: start;
    flex: 1;
    align-content: center;
}
.cart_layout_footer_position_footer_center {
    text-align: center;
    align-content: center;
}
.cart_layout_footer_position_footer_end{
    flex: 1;
    text-align: end;
    align-content: center;
}
.cart_block_items_item_quantity{
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart_block_items_item_quantity_text{
    text-align: center;
    flex:1;
    line-height: var(--cart_block_items_item_quantity_text_height);
}

.cart_block_account{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart_block_name {
    font-weight: bold;
}

img.cart_block_items_item_image {
    object-fit: cover;
}

.cart_block_items_item_holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_block_items_item {
    display: flex;
    justify-content: center;
    align-content: center;
}

.cart_block_items_item .cart_block_items_item_image img  {
    max-width: 100%;
}

.cart_block_items_item_price_holder {
    text-align: center;
}


.cart_block_items_item_price {
    display: block !important;
}

.cart_block_items_item_total {
    display: block !important;
    text-align: center;
}


.cart_block_items_item_name {
    font-weight: bold;
}

.cart_block_items_item_variants, .cart_block_items_item_addons {
    align-items: center;
    width: 100%;
}

.cart_block_items_item_variant, .cart_block_items_item_addon {
    display: flex;
    align-items: center;
    width: 100%;
}
.cart_block_items_item_variant_name{
    flex: 2;
}
.cart_block_items_item_variant_price{
    flex: 1;
    text-align:end;
}

.cart_block_items_item_addon_name {
    flex:2;
}
.cart_block_items_item_addon_price {
    flex:1;
    text-align:end;
}

.cart_block_items_item_quantity img {
    max-width:100%;
}


.cart_block_items_item_quantity_down {
    display: flex;
    justify-content: center;
    position: relative;
}

.cart_block_items_item_quantity_down_icon {
    font-size: var(--cart_block_items_item_quantity_down_width);
    line-height: var(--cart_block_items_item_quantity_down_height);
    width: var(--cart_block_items_item_quantity_down_width);
    height: var(--cart_block_items_item_quantity_down_height);
    padding: var(--cart_block_items_item_quantity_down_padding);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;

}

.cart_block_items_item_quantity_up {
    display: flex;
    justify-content: center;
    position: relative;
}

.cart_block_items_item_quantity_up_icon {
    font-size: var(--cart_block_items_item_quantity_up_width);
    line-height: var(--cart_block_items_item_quantity_up_width);
    width: var(--cart_block_items_item_quantity_up_width);
    height: var(--cart_block_items_item_quantity_up_height);
    padding: var(--cart_block_items_item_quantity_up_padding);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
}

.cart_block_items_item {
    position: relative;
}
.cart_block_items_item_total {
    font-weight: bold;
}

.cart_block_items_item_image_holder {
    text-align: center;
}

img.cart_block_items_item_remove {
    position: absolute !important;
    top: var(--cart_block_items_item_remove_marginVertical) ;
    left: var(--cart_block_items_item_remove_marginHorizontal) ;
    padding: 0px;
    margin: var(--cart_block_items_item_remove_padding);
}

.cart_block_items_item_info {
    align-items: center;
    display: block !important;
    min-height: 100%;
    align-content: center;
}
.cart_block_total {
    display: flex;
    align-items: center;
}


.cart_block_checkout_button {
    height: calc(var(--cart_block_checkout_button_height) - 2 * var(--cart_block_checkout_button_paddingVertical)) !important;
}
/* start login */

.login_layout {
    display: flex;
    flex-direction: column;
    height:100vh !important;
    overflow: scroll;
}

.login_layout_positions_holder {
    padding-top: var(--login_layout_paddingVertical);
    padding-bottom: var(--login_layout_paddingVertical);
    padding-right: var(--login_layout_paddingHorizontal);
    padding-left: var(--login_layout_paddingHorizontal);
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 952px;
    position: absolute !important;
    width: 100%;
}
.login_block_background_holder {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}
img.login_block_background {
    max-width: 100%;
    height: 100%;
    object-fit: cover !important;
    opacity: var(--login_block_background_alpha);
}

.login_center{
    width: 100% !important;
    overflow: auto;
}


.login_block_social {
    width: -webkit-fill-available;
}

.login_block_social_text_holder {
    display: flex;
}

.login_block_social_text {
    flex: 1;
    justify-content: center;
}

.login_block_social_text_background {
    flex: 3;
}


.login_block_social_buttons {
    display: flex;
    flex-direction: var(--login_block_social_orientation);
    margin: 0 auto;
    width: -webkit-fill-available;
}

.login_block_social_button {
    flex: 1;
    justify-content: center;
}


.login_layout_position_content_holder{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_block_logo img {
    max-width: 100%;
}

.login_layout_position_content {
    align-content: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.login_layout_position_top {
    align-content: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login_layout_position_bottom {
    align-content: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.login_block_signup {
    display: flex;
}

.login_block_forgot_password a {
    color: var(--login_block_forgot_password_textColor);
    text-decoration: none;
}

.login_block_signup_action a {
    color: var(--login_block_signup_action_textColor);
    text-decoration: none;
}

.login_layout.login_center img {
}

.login_block_title {
    font-weight: bold;
}

.login_block_mail_button {
    font-weight: bold;
    text-align: center;
}

.login_block_social_button {
    font-weight: bold;
    text-align: center;
}


.login_block_social_text_side {
    flex: 3;
}
.login_block_social_text_holder {
    align-items: center;
    justify-content: center;
    text-align:center;
}
.login_block_social_button_holder {
    display: flex;
    flex-direction: var(--login_block_social_orientation);
}
.login_block_forgot_password {
    text-align: end;
}
.login_block_title {
    text-align: center;
}

.login_block_register_error, .login_block_mail_error {
    text-align: center;
    color: red;
}

/* start product catalog */

.product_catalog_layout {
    height:100vh;
    overflow: scroll;
}
.product_catalog_layout_toolbar_position_toolbar {
    display: flex;
    height: 55px;
    overflow: hidden;
}
.product_catalog_layout_toolbar_position_toolbar {
    align-items: center;
    justify-content: center;
}
.product_catalog_layout_toolbar_position_toolbar_start {
    min-width: 50px;
    text-align: start;
}
.product_catalog_layout_toolbar_position_toolbar_center {
    flex: 1 1;
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;
}
.product_catalog_layout_toolbar_position_toolbar_end{
    min-width: 50px;
    text-align: end;
}

.product_catalog_layout_header_holder{
    background-color: var(--product_catalog_layout_header_background);
    height: var(--product_catalog_layout_header_height);
    position: relative;
    overflow: hidden;
}

.product_catalog_layout_header {
    background: transparent !important;
    position: relative;
}

.product_catalog_layout_header_overlay{
    position: absolute !important;
    width: 100%;
    height:auto;
}


.product_catalog_layout_header_position_featured_bottom * {
    z-index: 1000;
}

.product_catalog_layout_header_position_featured {
    width: 100%;
    height: 100%;
    position: relative;
}

.product_catalog_layout_header_position_featured_bottom {
    display: flex;
}

.product_catalog_layout_tabs{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
}
.product_catalog_layout_tabs .product_catalog_layout_tabs_item{
    flex: 1;
    text-align: center;
    font-weight: bold;
}

.product_catalog_layout_tabs .product_catalog_layout_tabs_item_selected{
    border-bottom: 2px solid var(--product_catalog_layout_tabs_indicator);
}

.product_catalog_layout_content_position_content{
    flex:var(--product_catalog_layout_content_top_position_content_weight);
    overflow: hidden;
    padding-bottom: 120px;
}

.product_catalog_block_logo {
    align-content: center;
}

img.product_catalog_block_logo {
    object-fit: contain !important;
}

.product_catalog_block_logo img {
    max-width: 100%;
    max-height: 100%;
}

.product_catalog_layout_footer_holder {
    position: fixed !important;
    bottom: 0px;
    width: 100%;
    z-index: 10000;
}
.product_catalog_layout_footer {
    width: auto;
    border-style: solid;
}
.product_catalog_layout_footer_positions {
    display: flex;
}
.product_catalog_layout_footer_position_footer_start {
    text-align: start;
}
.product_catalog_layout_footer_position_footer_center {
    text-align: center;
}
.product_catalog_layout_footer_position_footer_end{
    flex: 1;
    text-align: end;
}
input.product_catalog_block_search_input {
    width: -webkit-calc(100% - var(--product_catalog_block_search_input_marginHorizontal));
    border-style: solid;
    background-size: var(--product_catalog_block_search_input_icon_size);
    background-repeat: no-repeat;
    background-position: var(--product_catalog_block_search_input_icon_padding);
}

.product_catalog_block_search_input_text{
    padding: 0 var(--product_catalog_block_search_input_icon_padding);
}

img.product_catalog_block_search_input_icon {
    padding: 0px;
}

.product_catalog_block_items {
    display: grid;
    padding-top:0px !important;
    scrollbar-width: none;
}
.product_catalog_block_items_item {
    overflow: hidden;
    border-style: solid;
    display: grid;
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
}


.product_catalog_block_items div.product_catalog_block_items_item {
    width: auto;
    display: flex;
}
.product_catalog_block_items_item_offer {
    position: absolute !important;
    top: 0px;
    left: 0px;
}
.product_catalog_block_items_item_name{
    font-weight: bold;
}
.product_catalog_block_items_item_image {
    max-width: 100%;
    object-fit: cover;
}

.product_catalog_block_items_item_price_holder{
    display: flex;
    align-items: center;
    flex-direction: var(--product_catalog_block_items_item_sale_price_orientation);
    justify-content: var(--product_catalog_block_items_item_sale_price_gravity);

}

.product_catalog_block_items_item_sale_price{
    text-decoration: line-through;
}

.product_catalog_block_items_item_add_to_cart {
    height: calc(var(--product_catalog_block_items_item_add_to_cart_height) - 2 * var(--product_catalog_block_items_item_add_to_cart_paddingVertical)) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.product_catalog_block_items_item_info {
    flex: 1 !important;
}
.product_catalog_block_items_item_add_to_cart_text {
    font-weight: bold;
}


.product_catalog_block_items_item_add_to_cart_icon {
    width: calc(var(--product_catalog_block_items_item_add_to_cart_icon_width) - 2 * var(--product_catalog_block_items_item_add_to_cart_icon_padding)) !important;
    height: calc(var(--product_catalog_block_items_item_add_to_cart_icon_height) - 2 * var(--product_catalog_block_items_item_add_to_cart_icon_padding)) !important;
}


.product_catalog_block_items_item_price_quantity_holder {
    align-items: center;
    justify-content: center;
}
.product_catalog_block_items_item_quantity{
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product_catalog_block_items_item_quantity_text{
    text-align: center;
    flex:1;
    line-height: var(--product_catalog_block_items_item_quantity_text_height);
}


.product_catalog_block_items_item_quantity img {
    max-width:100%;
}

.product_catalog_block_items_item_quantity_down {
    height: var(--product_catalog_block_items_item_quantity_down_height);
    width: var(--product_catalog_block_items_item_quantity_down_width);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0px !important;
    position: relative;
}

.product_catalog_block_items_item_quantity_down_icon {
    height: var(--product_catalog_block_items_item_quantity_down_height);
    width: var(--product_catalog_block_items_item_quantity_down_width);
    font-size: var(--product_catalog_block_items_item_quantity_down_width);
    line-height: var(--product_catalog_block_items_item_quantity_down_height);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
}

.product_catalog_block_items_item_quantity_up {
    height: var(--product_catalog_block_items_item_quantity_up_height);
    width: var(--product_catalog_block_items_item_quantity_up_width);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0px !important;
    position: relative;
}

.product_catalog_block_items_item_quantity_up_icon {
    height: var(--product_catalog_block_items_item_quantity_up_height);
    width: var(--product_catalog_block_items_item_quantity_up_width);
    font-size: var(--product_catalog_block_items_item_quantity_up_width);
    line-height: var(--product_catalog_block_items_item_quantity_up_height);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
}


.product_catalog_block_cart {
    align-items: center;
    justify-content: center;
    display: flex;
}

.product_catalog_block_cart, .product_catalog_block_cart * {
    direction: var(--product_catalog_block_cart_direction) !important
}

.product_catalog_block_account{
    align-items: center;
    justify-content: center;
}
.product_catalog_block_categories {
    margin-top:0px !important;
    overflow: scroll;
    scrollbar-width: none;
}
.product_catalog_block_categories_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    flex: 0 0 auto;
}


.product_catalog_block_categories_item_content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: var(--product_catalog_block_categories_item_orientation);
    justify-content: var(--product_catalog_block_categories_item_gravity);
    align-items: center;
}

.product_catalog_block_categories_item_icon {
    max-width: 100%;
}

.product_catalog_block_categories_item_holder {
    position: absolute !important;
}

.product_catalog_block_categories_item_cover {
    max-width: 100%;
}



.product_catalog_block_whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product_catalog_block_upload {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product_catalog_block_upload_text {
    text-align: center;
}

.product_catalog_block_branch {
    display: flex;
    align-items: center;
    justify-content: center;
}
.product_catalog_block_branch_name {
    flex: 1;
}
.product_catalog_block_offers img {
    width: 100%;
    max-height: 100%;
}

.product_catalog_block_offers_offer {
    overflow: hidden;
}

.product_catalog_block_offers_dots {
    position: absolute !important;
    z-index: 10000;
    bottom: 0px;
    width: 20% !important;
    left: 40%;
    align-items: center;
    align-content: center;
    text-align: center;
}

.product_catalog_block_offers_dot {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    margin: 5px;
}


.product_catalog_block_offers .slick-dots {
    bottom: 25px;
}

.product_catalog_block_offers .slick-dots li button:before {
    font-size: 15px;
    color: var(--product_catalog_block_offers_default_background);
}

.product_catalog_block_offers .slick-dots li.slick-active button:before {
    color: var(--product_catalog_block_offers_selected_background);
}

.product_catalog_block_cart_number {
    align-items: center;
    justify-content: center;
    display: flex;
}

.product_catalog_block_cart_subtotal {
    align-items: center;
    justify-content: center;
    display: flex;
}

.product_catalog_block_cart_details{
    flex: 1;
    align-items: center;
    display: flex;
}

.product_catalog_block_cart_unit {
    flex: 1;
}

.product_catalog_layout_content {
    display: flex;
}


.product_catalog_block_loyalty_program_holder {
    flex-direction: var(--product_catalog_block_loyalty_program_orientation);
    width: 100%;
}


.product_catalog_block_loyalty_items {
    display: grid;
    padding-top:0px !important;
}
.product_catalog_block_loyalty_items_item {
    overflow: hidden;
    border-style: solid;
    display: flex;
    position: relative;
}

.product_catalog_block_loyalty_items_item_name{
    font-weight: bold;
}
.product_catalog_block_loyalty_items_item_image {
    max-width: 100%;
}


.product_catalog_block_loyalty_items_item_redeem {
    display: flex;
    align-items: center;
    justify-content: center;
}
.product_catalog_block_loyalty_items_item_info {
    flex: 1;
}
.product_catalog_block_loyalty_items_item_redeem_text {
    font-weight: bold;
}



div.product_catalog_layout_content {
    min-height: 500px;
}

.mode-sliding-menu {
    display: none;
    font-size: 16px;
    position: absolute !important;
    top: 0px;
    min-height: 100%;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.21);
    width: 100%;
}

.product_catalog_layout_navigation {
    min-height: 1542px;
    display: inline-block;
}

.product_catalog_block_user_menu_header {
    text-align: center;
}

.product_catalog_block_user_menu ul {
    padding: 0px;
}

.product_catalog_block_user_menu_item {
    padding: 0px !important;
    display: flex;
    text-align: center;
    align-items: center;
    border-bottom: var(--product_catalog_block_user_menu_strokeSize) solid var(--product_catalog_block_user_menu_strokeColor);
    flex: auto;
    text-decoration: none;
}

.product_catalog_block_user_menu_item a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--product_catalog_block_user_menu_item_textColor);
    width:100%;
}

.product_catalog_block_user_menu_item_text {
    padding: var(--product_catalog_block_user_menu_item_paddingVertical) var(--product_catalog_block_user_menu_item_paddingHorizontal);
}

img.product_catalog_block_user_menu_icon {
    padding: 0px;
    margin: var(--product_catalog_block_user_menu_icon_padding);
}

.product_catalog_block_user_menu {
    border: none !important;
}

.product_catalog_block_contact {
    display: flex;
    align-items: center;
}

.product_catalog_block_category_title {
    margin: 0 auto;
}


.mode-overlay {
    display: none;
    position: absolute !important;
    top: 0px;
    width: 100%;
    height: 1500px;
    background: #fff;
}

.product_catalog_layout_overlay {
    height:100vh;
    overflow: scroll;
}

.product_catalog_layout_overlay_toolbar {
    display: flex !important;
    height: 55px;
    overflow: hidden;
    align-items: center;
}

.product_catalog_layout_overlay_position_content {
    height: 100%;
}



.product_catalog_block_listing_group_list {
    overflow: scroll;
    scrollbar-width: none;
}


.toolbar_positions {
    align-items: center;
    justify-content: center;
}
.toolbar_positions_start {
    min-width: 50px;
    text-align: start;
}
.toolbar_positions_center {
    flex: 1;
    text-align: center;
}
.toolbar_positions_end{
    min-width: 50px;
    text-align: end;
}


.product_catalog_block_subcategories {
    display: flex;
    align-items: end;
    justify-content: end;
    height: 55px;
}

.product_catalog_block_subcategories .product_catalog_block_subcategories_item {
    flex: 1;
    text-align: center;
    font-weight: bold;
    line-height: 55px;
}

.product_catalog_block_subcategories .product_catalog_block_subcategories_item_selected {
    border-bottom: 2px solid var(--product_catalog_block_subcategories_indicator);
}


.product_catalog_layout_sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    z-index: 1000;
    transition: transform 0.3s ease;
}

.product_catalog_layout_sidebar.hidden {
    transform: translateX(100%);
}

.product_catalog_block_menu_icon img {
    max-width: 100%;
}

.product_catalog_block_user_account {
    display: flex;
    align-items: center;
}


.product_catalog_block_overlay_title {
    text-align: center;
}


.product_catalog_block_footer {
    margin-top: 0px !important;
    margin-bottom: var(--product_catalog_block_footer_padding_vertical);
    text-align: center;
}

a.product_catalog_block_footer_links_link {
    margin: 0px 5px;
}

/* start item details */

.item_details_layout {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
}

.item_details_layout_toolbar {
    display: flex;
    height: 55px;
    overflow: hidden;
}
.item_details_layout_toolbar_positions {
    align-items: center;
    justify-content: center;
}
.item_details_layout_header_position_header_start {
    min-width: 50px;
    text-align: start;
}
.item_details_layout_header_position_header_center {
    flex: 1;
    text-align: center;
}
.item_details_layout_header_position_header_end{
    min-width: 50px;
    text-align: end;
    align-content: center;
}
.item_details_layout_content_position_content{
    overflow: hidden;
}
.item_details_layout_footer_holder {
    position: absolute !important;
    bottom: var(--product_catalog_layout_footer_marginVertical);
    width: 100%;
    z-index: 10000;
}
.item_details_layout_footer {
    width: auto;
    border-style: solid;
}
.item_details_layout_footer_positions {
    display: flex;
}
.item_details_layout_footer_position_footer_start {
    text-align: start;
}
.item_details_layout_footer_position_footer_center {
    text-align: center;
}
.item_details_layout_footer_position_footer_end{
    flex: 1;
    text-align: end;
}
.item_details_layout_content_row_top,.item_details_layout_content_row_middle,.item_details_layout_content_row_top{
    display: flex;
}
.item_details_layout_content_row_top_position_start,.item_details_layout_content_row_middle_position_start,.item_details_layout_content_row_bottom_position_start{
    flex: 1;
    overflow: hidden;
}

.item_details_block_back {
    font-size: 25px;
}


.item_details_block_quantity {
    height: calc(var(--item_details_block_quantity_height) - 2* var(--item_details_block_quantity_paddingVertical)) !important;
}

.item_details_block_add_to_cart {
    height: calc(var(--item_details_block_add_to_cart_height) - 2* var(--item_details_block_add_to_cart_paddingVertical)) !important;
}


.item_details_block_quantity{
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item_details_block_quantity_text{
    text-align: center;
    flex:1;
    line-height: var(--item_details_block_quantity_text_height);
}

.item_details_block_quantity img {
    max-width:100%;
}

.item_details_block_quantity_down {
    height: calc(var(--item_details_block_quantity_down_height) - 2 * var(--item_details_block_quantity_down_padding)) !important;
    width: calc(var(--item_details_block_quantity_down_width) - 2 * var(--item_details_block_quantity_down_padding)) !important;
    display: flex;
    align-items: center;
    position: relative;
}

.item_details_block_quantity_down_icon {
    height: calc(var(--item_details_block_quantity_down_height) - 2 * var(--item_details_block_quantity_down_padding)) !important;
    width: calc(var(--item_details_block_quantity_down_width) - 2 * var(--item_details_block_quantity_down_padding)) !important;
    font-size: calc(var(--item_details_block_quantity_down_width) / 2);
    line-height: calc(var(--item_details_block_quantity_down_height) / 2);
    padding: var(--item_details_block_quantity_down_padding);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.item_details_block_quantity_up {
    height: calc(var(--item_details_block_quantity_up_height) - 2 * var(--item_details_block_quantity_up_padding)) !important;
    width: calc(var(--item_details_block_quantity_up_width) - 2 * var(--item_details_block_quantity_up_padding)) !important;
    display: flex;
    align-items: center;
    position: relative;
}

.item_details_block_quantity_up_icon {
    height: calc(var(--item_details_block_quantity_up_height) - 2 * var(--item_details_block_quantity_up_padding)) !important;
    width: calc(var(--item_details_block_quantity_up_width) - 2 * var(--item_details_block_quantity_up_padding)) !important;
    font-size: calc(var(--item_details_block_quantity_up_width) / 2);
    line-height: calc(var(--item_details_block_quantity_up_height) / 2);
    padding: var(--item_details_block_quantity_up_padding);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.item_details_block_cart {
    align-items: center;
    justify-content: center;
    display: flex;

}

.item_details_block_cart_details {
    align-items: center;
    display: flex;
}

.item_details_block_cart_number {
    justify-content: center;
    align-items: center;
    display: flex;
}
.item_details_block_account{
    display: flex;
    align-items: center;
    justify-content: center;
}
.item_details_block_name {
    font-weight: bold;
}

.item_details_block_images .slick-dots {
    bottom: 25px;
}


.item_details_block_images .slick-dots li button:before {
    font-size: 15px;
    color: var(--item_details_block_images_default_background);
}

.item_details_block_images .slick-dots li.slick-active button:before {
    color: var(--item_details_block_images_selected_background);
}


button.slick-disabled {
    display: none !important;
}



.item_details_block_images_image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}



.item_details_block_price {
    display: flex;
    align-items: center;
}

.item_details_block_price_sale_price {
    flex: 1;
    font-weight: bold;
}

.item_details_block_variants {
    flex-direction: column !important;
}

.item_details_block_variants_groups_list {
    display: flex;
    flex-direction: var(--item_details_block_variants_orientation);
    overflow: scroll;
    scrollbar-width: none;
}

.item_details_block_variants_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
}

.item_details_block_variants_title {
    font-weight: bold;
}
.item_details_block_variants_item_name {
    flex: 1;
}

.item_details_block_variants_item_selected .item_details_block_variants_item_name {
    color: var(--item_details_block_variants_item_name_selected_textColor) !important;
}

.item_details_block_add_to_cart_text {
    width:100%;
    text-align: center;
}

.item_details_block_addons_list {
    display: flex;
    flex-direction: var(--item_details_block_addons_orientation);
}

.item_details_block_addons_item {
    display: flex;
    flex-direction: row;
}

.item_details_block_addons_title {
    font-weight: bold;
}
.item_details_block_addons_item_name {
    flex: 1;
}

.item_details_block_specs_item * {
    display: inline-block;
}



.item_details_block_availability_holder {
    overflow: scroll;
}


.item_details_block_availability {
    width: fit-content !important;
    align-items: start;
}


.item_details_block_availability_group {
    overflow: hidden;
}


.item_details_layout_content_row_middle_position_start { /* shit need to be fixed */
    overflow: hidden;
}
/* START BUILDER GENERATED CSS */


/* start address */

.address_layout {
    height:100vh;
    overflow: scroll;
}

.address_layout_toolbar {
    display: flex;
    height: 55px;
    overflow: hidden;
}
.address_layout_toolbar_positions {
    align-items: center;
    justify-content: center;
}
.address_layout_header_position_header_start {
    min-width: 50px;
    text-align: start;
}
.address_layout_header_position_header_center {
    flex: 1;
    text-align: center;
}
.address_layout_header_position_header_end{
    min-width: 50px;
    text-align: end;
}

.address_layout_content {
    padding-bottom: 80px;
}

.address_layout_content_position_content{
    overflow: hidden;
    height: 100%;
}
.address_layout_footer_holder {
    position: absolute  !important;
    bottom: var(--address_layout_footer_marginVertical);
    width: 100%;
    z-index: 10000;
}
.address_layout_footer {
    width: auto;
    border-style: solid;
}
.address_layout_footer_positions {
    display: flex;
}
.address_layout_footer_position_footer_start {
    text-align: start;

}
.address_layout_footer_position_footer_center {
    text-align: center;
    flex: 1;
}
.address_layout_footer_position_footer_end{
    text-align: end;
}
.address_block_addresses_item {
    display: flex;
    align-items: center;
    justify-content: center;
}

img.address_block_addresses_item_icon {
    max-width: 100%;
    width: var(--address_block_addresses_item_icon_height);
    height: auto;
}
.address_block_addresses_item_icon{
    flex:1;
}
.address_block_addresses_item_line_holder {
    flex:4;
}
.address_block_addresses_item_default {
    flex:1;
}




.address_block_map_map {
    position: relative;
}

.address_block_map_marker {
    position: absolute !important;
    z-index: 10000;
    height: 50px;
    width: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.address_block_form_default {
    margin-right: var(--address_block_form_input_marginHorizontal);
    margin-left: var(--address_block_form_input_marginHorizontal);
}

.address_block_addresses {
    border: 0px;
}

select.address_block_form_input {
    appearance:none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 3%;
    background-position-y: 50%;
}



/* start checkout */

.checkout_layout {
    height:100vh;
    overflow: scroll;
}

.checkout_layout_toolbar {
    display: flex;
    height: 55px;
    overflow: hidden;
}
.checkout_layout_toolbar_positions {
    align-items: center;
    justify-content: center;
}
.checkout_layout_header_position_header_start {
    min-width: 50px;
    text-align: start;
}
.checkout_layout_header_position_header_center {
    flex: 1;
    text-align: center;
}
.checkout_layout_header_position_header_end{
    min-width: 50px;
    text-align: end;
}
.checkout_layout_content_position_content{
    overflow: hidden;
}
.checkout_layout_footer_holder {
    position: absolute !important;
    bottom: var(--checkout_layout_footer_marginVertical);
    width: 100%;
    z-index: 10000;
}
.checkout_layout_footer {
    width: auto;
    border-style: solid;
}
.checkout_layout_footer_positions {
    display: flex;
}
.checkout_layout_footer_position_footer_start {
    text-align: start;
    flex: 1;
}
.checkout_layout_footer_position_footer_center {
    text-align: center;
}
.checkout_layout_footer_position_footer_end{
    flex: 1;
    text-align: end;
}
.checkout_block_account{
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkout_block_name {
    font-weight: bold;
}



.checkout_block_items_item_holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout_block_items_item {
    display: flex;
    justify-content: center;
    align-content: center;
}

.checkout_block_items_item_price_holder {
    text-align: center;
}


.checkout_block_items_item_price {
    display: block !important;
}

.checkout_block_items_item_total {
    display: block !important;
    text-align: center;
}


.checkout_block_items_item_name {
    font-weight: bold;
}

.checkout_block_items_item_variants, .checkout_block_items_item_addons {
    align-items: center;
    width: 100%;
}

.checkout_block_items_item_variant, .checkout_block_items_item_addon {
    display: flex;
    align-items: center;
    width: 100%;
}

.checkout_block_items_item_variant_name{
    flex: 2;
}
.checkout_block_items_item_variant_price{
    flex: 1;
    text-align:end;
}

.checkout_block_items_item_addon_name {
    flex:2;
}
.checkout_block_items_item_addon_price {
    flex:1;
    text-align:end;
}



.checkout_block_items_item {
    position: relative;
}
.checkout_block_items_item_total {
    font-weight: bold;
}

.checkout_block_items_item_image_holder {
    text-align: center;
}



.checkout_block_items_item_details {
    flex:3;
    padding-right:var(--cart_block_items_item_details_paddingHorizontal);
    padding-left:var(--cart_block_items_item_details_paddingHorizontal);
    padding-top:var(--cart_block_items_item_details_paddingVertical);
    padding-bottom:var(--cart_block_items_item_details_paddingVertical);
}


.checkout_block_items_item_info {
    flex:1;
    align-items: center;
    display: block !important;
    min-height: 100%;
    align-content: center;
    margin-top: var(--cart_block_items_item_info_marginVertical);
    margin-bottom: var(--cart_block_items_item_info_marginVertical);
    margin-right: var(--cart_block_items_item_info_marginHorizontal);
    margin-left: var(--cart_block_items_item_info_marginHorizontal);
}




.checkout_block_items_item_total {
    font-weight: bold;
}
.checkout_block_total {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.checkout_block_summary_holder {
    display:flex;
    align-items: center;
}
.checkout_block_summary_subtotal_text,.checkout_block_summary_discount_text,
.checkout_block_summary_tax_text,.checkout_block_summary_service_fee_text,.checkout_block_summary_total_text{
    flex: 1;
}
.radio_group {
    display: flex;
    align-items: center;
}
.checkout_block_summary_total, .checkout_block_summary_total_text {
    font-weight: 500;
}
.checkout_block_address_manage_addresses.button {
    width: fit-content;
}
.checkout_block_items_item_quantity_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.checkout_block_coupon {
    display: flex !important;
    flex-direction: column !important;
}


.checkout_block_coupon_holder {
    display: flex;
    flex-direction: var(--checkout_block_coupon_orientation);
}

.checkout_block_coupon_code {
    height: calc(var(--checkout_block_coupon_code_height) - 2 * var(--checkout_block_coupon_code_paddingVertical)) !important;
}

.checkout_block_coupon_button {
    height: calc(var(--checkout_block_coupon_button_height) - 2 * var(--checkout_block_coupon_button_paddingVertical)) !important;
}

.checkout_block_confirm_button {
    height: calc(var(--checkout_block_confirm_button_height) - 2 * var(--checkout_block_confirm_button_paddingVertical)) !important;
}

.checkout_block_confirmation_continue_button {
    height: calc(var(--checkout_block_confirmation_continue_button_height) - 2 * var(--checkout_block_confirmation_continue_button_paddingVertical)) !important;
}

.checkout_block_payment_title_text,.checkout_block_coupon_text,.checkout_block_address_title_text, .checkout_block_note_title_text, .checkout_block_service_title_text, .checkout_block_coupon_title_text {
    font-weight: 500;
}
.checkout_block_payment_add_credit_card {
    width: fit-content;
}

.checkout_block_service_item {
    display: flex;
}

.checkout_block_service_item_name {
    flex: 1;
}

.checkout_block_confirmation_holder {
    text-align: center;
    background-color: var(--checkout_block_confirmation_background);
    border-color: var(--checkout_block_confirmation_strokeColor);
    border-style: solid;
    padding-top: var(--checkout_block_confirmation_paddingVertical);
    padding-bottom: var(--checkout_block_confirmation_paddingVertical);
    padding-right: var(--checkout_block_confirmation_paddingHorizontal);
    padding-left: var(--checkout_block_confirmation_paddingHorizontal);
    margin-right: var(--checkout_block_confirmation_marginHorizontal);
    margin-left: var(--checkout_block_confirmation_marginHorizontal);
    margin-top: var(--checkout_block_confirmation_marginVertical);
    margin-bottom: var(--checkout_block_confirmation_marginVertical);
    border-width: var(--checkout_block_confirmation_strokeSize);
    border-radius: var(--checkout_block_confirmation_radius);
    box-shadow: var(--checkout_block_confirmation_elevation) var(--checkout_block_confirmation_elevation) var(--checkout_block_confirmation_elevation) var(--checkout_block_confirmation_elevation) #eee;
    margin: 220px 0px;
}

.checkout_block_confirmation_order_number {
    border: none !important;
}

.checkout_block_confirmation.mode-confirm {
    border: none !important;
    background: none !important;
}



/* start orders */

.orders_layout {
    height:100vh;
    overflow: scroll;
}
.orders_layout_toolbar {
    display: flex;
    height: 55px;
    overflow: hidden;
}
.orders_layout_toolbar_positions {
    align-items: center;
    justify-content: center;
}
.orders_layout_header_position_header_start {
    min-width: 50px;
    text-align: start;
}
.orders_layout_header_position_header_center {
    flex: 1;
    text-align: center;
}
.orders_layout_header_position_header_end{
    min-width: 50px;
    text-align: end;
}
.orders_layout_content_position_content{
    overflow: hidden;
    height: 100%;
}
.orders_block_orders_item_holder {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.orders_block_orders_item_branch {
    font-weight: bold;
}
.orders_block_orders_item_number{
    text-align:start;
}
.orders_block_orders_item_date{
    text-align:end;
}
.orders_block_orders_item_total{
    text-align:end;
    font-weight: bold;
}
.orders_block_orders_item_status{
    font-weight: bold;
    text-align:start;
}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.modal-body {
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 10px;
    text-align: start;
}
.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}






@media (min-width: 451px) {

    .App{
        max-width: 450px;
        position: relative;
    }

    .product_catalog_layout_footer_holder{
        max-width: 450px;
    }

    .app_holder {
        display: flex;
        max-height: 100vh;
        overflow: hidden;
    }

    .cover {
        flex: 1;
        min-height: 100%;
        background-color: var(--global_colorPrimaryDark) !important;
        border-right: 2px solid #00000004;
        display: flex !important;
        align-items: center;
        overflow: hidden;
        position: relative;
        justify-content: center;
    }

    .cover-logo img {
        max-width: 250px;
    }

}













